@import '@styles/main.scss';

.page-not-found {
    text-align: center;
    padding: 2rem 5rem;
    h1 {
        font-size: 80px;
    }
    h3 {
        font-size: 20px;
    }

    @include media-min-sm {
        text-align: left;
        h1 {
            font-size: 150px;
        }
        h3 {
            font-size: 40px;
        }
    }
}