@import "./variables.scss";
@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@400;500;700;800&display=swap);
@import url(https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700%7CRoboto:300,400,500,600,700);

body {
    font-family: Poppins, Helvetica, "sans-serif";
    font-size: 14px;
}

[data-env="uat"], [data-env="staging"] {
    [data-env-impose="true"] {
      position: relative;
    
      &::before {
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%) rotate(-30deg);
        transform: translate(-50%, -50%) rotate(-15deg);
        color: #d94e35;
        font-size: 60px;
        border-top: 5px solid #d94e35;
        border-bottom: 5px solid #d94e35;
        font-weight: 900;
        text-transform: uppercase;
        text-shadow: 0px 5px #c5c5db;
      }
      &.brand-logo::before {
        font-size: 30px;
        transform: translate(-50%, -50%) rotate(-10deg);
      }
    }
  
    &[data-env="staging"] {
      [data-env-impose="true"] {
        &::before {
          content: "TEST";
        }
      }
    }
  
    &[data-env="uat"] {
      [data-env-impose="true"] {
        &::before {
          content: "UAT";
        }
      }
    }
    
}

#root {
    
    @include media-min-md {
        display: flex;
        align-items: center;
        height: 100vh;
        justify-content: center;
        
        &::before {
            animation-fill-mode: both;
            background: #fff;
            content: " ";
            height: 100%;
            position: fixed;
            right: 0;
            top: 0;
            transform-origin: right;
            width: 50%;
        }

        &.no-half-bg::before {
            content: none;
        }
    }

    .input-group {
        .input-group-prepend .input-group-text {
            border: 0;
            background-color: #f2f5f8;
            padding-right: 0;
        }
    }

    .form-control {
        display: block;
        width: 100%;
        font-weight: 400;
        line-height: 1.5;
        background-clip: padding-box;
        transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
        // height: calc(2.5em + .75rem + 2px);
        border-radius: 10px;
        font-family: Helvetica, Arial, sans-serif !important;
        font-size: 15px;
        color: #525252;
        transition: 0ms !important;
        outline: none !important;
        border-radius: 5px;
        border: 0;
        padding: 22px 17px;
        background-color: #f2f5f8;
        box-shadow: none;

        &::placeholder {
            color: #aaaaaa;
        }
    }

    .btn {
        font-weight: 400;
        text-align: center;
        vertical-align: middle;
        font-size: 1rem;
        line-height: 1.5;
        padding: 10px 50px;
        font-size: 16px;
        border-radius: 5px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border-width: 0;
    }

    .request-form {
        margin: 15px 0;

        width: 100%;

        img {
            max-width: 300px;
            max-height: 100px;
        }

        @include media-min-sm {
            width: 480px;
        }
    }

    .pay-out {
        background-color: #f9f9f9;
        border-radius: 20px;
        font-weight: 500;

        .pay-out-details {
            padding: 30px 35px;

            .pay-out-description {
                font-size: 12px;
                white-space: pre-wrap;
                display: block;
                text-align: justify;
                line-height: 2;
            }
        }

        .pay-out-total {
            position: relative;
            padding: 30px 35px;
            border-top: 3px dashed #ccc;
            display: flex;
            justify-content: space-between;

            .pay-out-total-icon {
                display: flex;
                align-items: center;
                font-size: 25px;
            }
            
            &::before, &::after {
                content: "";
                position: absolute;
                top: -1px;
                background-color: #fcfcfc;
                border-radius: 50px;
                width: 40px;
                height: 40px;
            }

            &::before {
                transform: translate(-50%, -50%);
                left: 0;
            }

            &::after {
                transform: translate(50%, -50%);
                right: 0;
            }
        }
    }

    .card {
        background-color: #f5f5f5;
        border: 0;
        border-radius: 20px;
        box-shadow: 0px 3px 5px 2px rgba(73, 80, 87, 0.09);
    }

    .checkmark {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        display: block;
        stroke-width: 2;
        stroke: #4bb71b;
        stroke-miterlimit: 10;
        box-shadow: inset 0px 0px 0px #4bb71b;
        animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
        position:relative;
        top: 5px;
        right: 5px;
        margin: 0 auto 30px;
    }
    
    .checkmark__circle {
        stroke-dasharray: 166;
        stroke-dashoffset: 166;
        stroke-width: 2;
        stroke-miterlimit: 10;
        stroke: #4bb71b;
        fill: #fff;
        animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
     
    }
    
    .checkmark__check {
        transform-origin: 50% 50%;
        stroke-dasharray: 48;
        stroke-dashoffset: 48;
        animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
    }
    
    @keyframes stroke {
        100% {
            stroke-dashoffset: 0;
        }
    }
    
    @keyframes scale {
        0%, 100% {
            transform: none;
        }
    
        50% {
            transform: scale3d(1.1, 1.1, 1);
        }
    }
    
    @keyframes fill {
        100% {
            box-shadow: inset 0px 0px 0px 30px #4bb71b;
        }
    }
}