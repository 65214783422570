.card-element-form {

  .skeleton-placeholder {
    height: 17px;
  }

  .skeleton-placeholder:not(.no-margin) {
    margin-top: 20px;
    margin-bottom: 20px;
    border-radius: 5px;
  }

  &.flat {
    .skeleton-placeholder:not(.no-margin) {
      margin-top: 22px;
      margin-bottom: 22px;
    }
    .StripeElement {
      border: 0;
      padding: 14px 17px;
      background: #f2f5f8;
    }
  }

  .StripeElement {
    display: block;
    // margin: 10px 0 20px 0;
    border: 1px solid #bebebe;
    padding: 10px 17px;
    background: #fff;
    font-family: Helvetica, Arial, sans-serif !important;
    font-size: 15px;
    color: #525252;
    margin-bottom: 10px;
    transition: 0ms !important;
    outline: none !important;
    border-radius: 5px;
  }

  input::placeholder {
    color: #aab7c4;
  }

  .StripeElement.IdealBankElement,
  .StripeElement.PaymentRequestButton {
    padding: 0;
  }
  .ElementsApp input {
      font-size: 16px;
  }
}