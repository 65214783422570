@import '@styles/main.scss';


.response-form-layout {
    
    .introduction {
        display: flex;
        flex-direction: column;

        a {
            text-decoration: underline;
        }
    }
    
    .section-header {
        padding-top: 15px;
        text-align: center;
        
        @include media-min-sm {
            padding-top: 0px;
            text-align: left;
        }

        h1 {
            font-size: 25px;

            @include media-min-sm {
                font-size: 35px;
            }
        }

        h5 {
            font-size: 15px;

            @include media-min-sm {
                font-size: 20px;
            }
        }
    
        h3 {
            font-family: 'Raleway', sans-serif;
        }

        .address {
            white-space: pre;
            margin-bottom: 15px;
        }

        .contact-us {
            p {
                margin-bottom: 0px;
            }
        }
    
        .loader-spinner {
            background-color: transparent;
        }

        img {
            max-width: 300px;
            max-height: 100px;
    
            &[data-facade="true"] {
                height: 100px;
            }
        }
    }

    .col-form {
        display: flex;
        flex-direction: column;    
        padding-top: 20px;
        padding-bottom: 20px;
        background-color: #fff;

        @include media-min-sm {
            padding-top: 0px;
            padding-bottom: 0px;
        }
    }
    
    .section-form {
        position: relative;

        .divider {
            display: flex;
            align-items: center;
            overflow: hidden;
            position: relative;
            padding-bottom: 32px;
            padding-top: 16px;

            hr {
                width: 100%;
            }

            .divider-text {
                background-color: #fff;
                left: 50%;
                padding: 0 8px;
                position: absolute;
                transform: translateX(-50%);
                white-space: nowrap;
                margin-bottom: 0;
            }
        }

        h1 {
            font-size: 40px;
            margin-top: 30px;
            margin-bottom: 30px;
        }

        p {
            font-size: 14px;
        }
    
        .alert {
            padding: 1.2rem 1.25rem;
            font-size: 14px;
        }
    }

    .page-not-found {
        text-align: center;
        padding: 2rem 5rem;
        h1 {
            font-size: 80px;
        }
        h3 {
            font-size: 20px;
        }

        @include media-min-sm {
            text-align: left;
            h1 {
                font-size: 150px;
            }
            h3 {
                font-size: 40px;
            }
        }
    }
}